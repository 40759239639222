import $ from "jquery"
import insist from "@/vendor/insist";

export class Route
   constructor: (path, @callbacks) ->
      insist.args(arguments, String, arrayOf(Function))

      # Capture human readable path for debugging and testing
      @path = path

      # eslint-disable-next-line no-useless-escape
      path = path.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&")

      # Capture the slugs (/:something) and replace them with regex fragment.
      replaceColon = (slug) -> return slug.substring(2)
      @slugs = (path.match(/\/:(\w+)/g) or []).map(replaceColon)
      expression = path.replace(/\/:(\w+)/g, "/([\\w-]+)")
      @regex = new RegExp(expression)

   checkUrl: (url) =>
      insist.args(arguments, String)
      @regex.lastIndex = 0

      # Strip out query string or hash fragment and return path
      # /path?hello_world=true -> /path
      path = url.replace(/\?.*|#.*/, "")

      # Strip trailing slash
      # /path/ -> /path
      path = path.substring(0, path.length - 1) if path[path.length - 1] == "/"

      # Get the query string
      # /path?hello_world=true -> (?hello_world=true || "")
      query = url.match(/\?.*/)?[0].replace(/#.*/, "") or ""

      # Strip out and return just hash fragment
      # /path?hello_world=true#action_name -> #action_name
      fragment = url.match(/#.*/)?[0] or ""

      # Takes just path "/path" and matches it with regex
      match = @regex.exec(path)

      if match != null && match[0].length == path.length
         req = {}
         # Add the slugs to the request object.
         if @slugs
            req.params = {}
            req.params[slug] = match[index + 1] for slug, index in @slugs
         # Add the query parameters to the request object.
         if query.length
            req.query = {}
            values = query.substring(1).split("&")
            for value in values
               split = value.split("=")
               req.query[split[0]] = split[1] or true

         # Add the fragment to the request.
         req.fragment = fragment if fragment
         @fireRouteCallbacks(req)
         return true
      return false

   fireRouteCallbacks: (req) ->
      callbackIndex = 0
      callbackCount = @callbacks.length
      next = =>
         callbackIndex++
         unless callbackIndex == callbackCount
            @callbacks[callbackIndex](req, next)

      @callbacks[callbackIndex](req, next)

export class Router
   constructor: () ->
      @routes = []
      @on404 = (url) -> console.log('404 at', url)
      @historyLength = 0
      @currentRoute = null
      @basePath = ''
      @ignoredPaths = ["/webclients/host/companies/tools/workforce-planning/oauth/callback"]


   setBasePath: (basePath) ->
      @basePath = if basePath then basePath else ''

   registerRoute: (path, callbacks...) ->
      assertOfType(path, String)
      assertOfType(callbacks, arrayOf(Function))

      finalUrl = if @ignoredPaths.includes(path)
         path
      else
         @basePath + path


      @routes.push(new Route(finalUrl, callbacks))

   ### Starts the router listening. The initial URL is passed through the router immediately. ###
   listen: () ->
      window.addEventListener "popstate", () =>
         @historyLength--
         @notify_(@currentUrlWithoutOrigin_())

      # Capture all clicks on links. Have to use self because 'this' is the element clicked.
      self = @
      $(".labor-chart-click-listener").on "click", "[href]", (e) ->
         href = @getAttribute("href")
         # Let external links behave normally.
         if href.indexOf("http") != 0
            e.preventDefault()
            self.navigate(null, href)

      @notify_(@currentUrlWithoutOrigin_())

   ### Navigates to the supplied URL. ###
   navigate: (pageName, url, forceReload) ->
      insist.args(arguments, nullable(String), String, optional(Boolean))

      forceReload = false unless forceReload

      unless forceReload
         # Check to see if there is storage for a given page name
         savedStateUrl = if pageName? then window.sessionStorage.getItem(pageName) else null
         url = savedStateUrl if savedStateUrl?

         return if @addOriginIfNeeded_(@basePath + url) == window.location.href

      if @changeUrl_(url, true)
         ## notify needs basePath now, but not when being initialized?
         @notify_(@basePath + url)

   ###
    # Redirects to the supplied URL. This uses a pop instead of a push to mimic how redirects
    # works with http
   ###
   redirect: (url) ->
      insist.args(arguments, String)
      return if @addOriginIfNeeded_(url) == window.location.href
      if @changeUrl_(url, false)
         @notify_(url)

   # For security purposes.
   hardNavigate: (url) ->
      insist.args(arguments, String)
      location.href = url

   setUrlHash: (hash) ->
      window.location.hash = hash

   updateUrlQueryParam: (pageName, paramKey, paramVal, newEntry) ->
      insist.args(arguments, nullable(String), String, [String, Number, Boolean], optional(Boolean))
      urlParts = window.location.href.split("?")
      if urlParts[1]? and urlParts[1] != ""
         paramSets = urlParts[1].split("&")
         existingSet = null
         for set in paramSets
            if paramKey == set.split("=")[0]
               existingSet = set
               break
         newSet = "#{paramKey}=#{paramVal}"
         if existingSet?
            paramSets[paramSets.indexOf(existingSet)] = newSet
         else
            paramSets.push(newSet)

         url = urlParts[0] + "?#{paramSets.join('&')}"
      else
         url = urlParts[0] + "?#{paramKey}=#{paramVal}"

      @changeUrl_(@formatUrl_(url), newEntry)

      # Save url in local storage
      if pageName?
         formattedStateUrl = url.replace(@getOrigin_(), "")
         window.sessionStorage.setItem(pageName, formattedStateUrl)

   removeQueryParam: (pageName, paramKey, newEntry) ->
      insist.args(arguments, nullable(String), String, optional(Boolean))
      urlParts = window.location.href.split("?")
      if urlParts[1]? and urlParts[1] != ""
         paramSets = urlParts[1].split("&")
         existingSet = null
         for set in paramSets
            if paramKey == set.split("=")[0]
               existingSet = set
               break
         if existingSet?
            paramSets.splice(paramSets.indexOf(existingSet), 1)

            url = urlParts[0] + "?#{paramSets.join('&')}"
            @changeUrl_(@formatUrl_(url), newEntry)

            # Save url in local storage
            if pageName?
               formattedStateUrl = url.replace(@getOrigin_(), "")
               window.sessionStorage.setItem(pageName, formattedStateUrl)

   getCurrentQueryParams: ->
      urlParts = window.location.href.split("?")
      if urlParts[1]? and urlParts[1] != ""
         params = {}
         paramSets = urlParts[1].split("&")
         for set in paramSets
            paramKeyVal = set.split("=")
            params[paramKeyVal[0]] = paramKeyVal[1]
         return params
      else
         return {}

   updateUrlPathParam: (paramKey, newVal, newEntry) ->
      insist.args(arguments, String, String, optional(Boolean))
      urlParts = window.location.href.split("/")
      paramKeyIndex = urlParts.indexOf(paramKey)
      return if paramKeyIndex == -1
      valIndex = paramKeyIndex + 1
      urlParts[valIndex] = newVal
      url = urlParts.join('/')
      @changeUrl_(@formatUrl_(url), newEntry)

      ignoreKeys = ['intercom-state']
      for n in [0...window.sessionStorage.length]
         keyName = window.sessionStorage.key(n)
         continue if ignoreKeys.indexOf(keyName) != -1
         savedUrl = window.sessionStorage.getItem(keyName)
         savedUrlParts = savedUrl.split("/")
         keyIndex = savedUrlParts.indexOf(paramKey)
         return window.sessionStorage.removeItem(keyName) if keyIndex == -1
         valIndex = keyIndex + 1
         savedUrlParts[valIndex] = newVal
         window.sessionStorage.setItem(keyName, savedUrlParts.join('/'))

   ### Returns a value indicating if there is state to go back to. ###
   hasHistory: ->
      return @historyLength > 0

   ### Navigates to the previous URL. ###
   back: ->
      window.history.back()

   ### Uses push state to navigate to the given URL if possible. ###
   changeUrl_: (url, newEntry) ->
      finalUrl = @basePath + url

      insist.args(arguments, String, optional(Boolean))
      unless finalUrl.match(/tel:|mailto:/)?
         if newEntry && window.history && window.history.pushState
            history.pushState(null, "", finalUrl)
            @historyLength++
            return true
         else if !newEntry && window.history && window.history.replaceState
            history.replaceState(null, "", finalUrl)
            return true

      window.location.href = finalUrl
      return false

   ### Calls the proper callbacks based on the supplied URL. ###
   notify_: (url) ->
      insist.args(arguments, String)
      for route in @routes
         return if route.checkUrl(url)
      @on404(url)

   ### Gets the current URL without the origin ###
   currentUrlWithoutOrigin_: ->
      return window.location.href.replace(@getOrigin_(), "")

   addOriginIfNeeded_: (url) ->
      return url if url.indexOf("http") == 0
      url = if url[0] == "/" then url else "/#{url}"
      return "#{@getOrigin_()}#{url}"

   ### Returns the current origin. This is pulled out to make testing possible. ###
   getOrigin_: ->
      return window.location.origin

   formatUrl_: (url) ->
      return if !!@basePath
         url.replace(@getOrigin_() + @basePath, '')
      else
         url


export router = new Router()